<template>
  <span
    v-if="isIconMaterial"
    :class="['material-symbols-outlined', props.class && props.class]"
    :style="{
      color: iconColor,
      fontSize: `${getIconSize}px`,
    }"
  >
    {{ name }}
  </span>
  <div v-else>
    <component
      :is="iconComponent"
      :class="[baseClass && props.class]"
      :data-comp="baseClass"
      :style="{
        color: iconColor,
        fontSize: `${getIconSize}px`,
      }"
      v-bind="props"
    >
    </component>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed as vComputed } from "vue";
import * as Icons from "../Icons";
import { IconDimension, IconName } from "./types";
import { dimensionsMap, materialIconNames } from "./const";

const baseClass = "gt-icon";

const props = defineProps({
  name: {
    type: String as PropType<IconName>,
    required: true,
  },
  size: {
    type: String as PropType<IconDimension>,
    default: "base",
  },
  alt: {
    type: String,
    default: "",
  },
  ariaLabel: {
    type: String,
    default: "",
  },
  ariaHidden: {
    type: Boolean,
    default: false,
  },
  tabIndex: {
    type: Number,
    default: null,
  },
  iconColor: {
    type: String as PropType<String>,
    default: "white",
  },
  class: {
    type: String,
    default: undefined,
  },
});

const iconComponent = vComputed(() => {
  /* eslint import/namespace: ['error', { allowComputed: true }] */
  return Icons[props.name as keyof typeof Icons];
});
const getIconSize = vComputed(() => dimensionsMap[props.size]);
const isIconMaterial = vComputed(() => materialIconNames.includes(props.name));
</script>
