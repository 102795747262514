<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { useGtm } from "@gtm-support/vue-gtm";
import { useDebounce } from "./utils/debounce";
import { useScrollIntoView } from "./utils/scrollIntoView";

const nuxtApp = useNuxtApp();
const route = useRoute();
const debouncedScroll = useDebounce(
  () => useScrollIntoView(route.hash, 50),
  500
);

// Watches for changes in the Nuxt payload to detect page updates or navigation changes.
// If the current route has a hash (e.g., #section-id), it triggers the debounced scroll function.
watch(nuxtApp.payload, () => {
  if (route.hash) {
    debouncedScroll();
  }
});

const gtm = useGtm();
gtm?.enable(true);

const title =
  "GenTwo: Expanding Your Investment Universe | Making all Assets Investable.";
const { g2ProApiBaseUrl, allowSearchIndexing } = useRuntimeConfig().public;
const strapiBaseUrl = useStrapiUrl();

useHead({
  htmlAttrs: { lang: "en-US" },

  titleTemplate: (titleChunk) => {
    return titleChunk
      ? titleChunk.includes("GenTwo")
        ? titleChunk
        : `${titleChunk} – GenTwo`
      : title;
  },
  link: [
    {
      key: "perf-g2ProApiBaseUrl",
      rel: "preconnect",
      href: g2ProApiBaseUrl,
    },
    { key: "perf-strapiBaseUrl", rel: "preconnect", href: strapiBaseUrl },
    { rel: "icon", type: "image/x-icon", href: "/favicon.ico" },
  ],
  meta: [
    { charset: "utf-8" },
    {
      key: "apple-mobile-web-app-title",
      name: "apple-mobile-web-app-title",
      content: "GenTwo",
    },
    {
      key: "description",
      name: "description",
      content:
        "Expand your investment universe with GenTwo. Leverage our unique financial engineering network. We make all assets bankable and investable. Explore our GenTwo PRO platform today.",
    },
    { key: "og:type", property: "og:type", content: "website" },
    {
      key: "og:site_name",
      property: "og:site_name",
      content: "GenTwo",
    },
    {
      key: "og:title",
      property: "og:title",
      content:
        "Expand your investment universe with GenTwo. Leverage our unique financial engineering network. We make all assets bankable and investable. Explore our GenTwo PRO platform today.",
    },
    {
      key: "og:description",
      property: "og:description",
      content:
        "Expand your investment universe with GenTwo. Leverage our unique financial engineering network. We make all assets bankable and investable. Explore our GenTwo PRO platform today.",
    },
    ...(allowSearchIndexing ? [] : [{ name: "robots", content: "noindex" }]),
  ],
  base: { target: "_blank" },
});
</script>
