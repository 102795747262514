<template>
  <div :class="baseClass" :data-comp="baseClass">
    <div
      class="flex items-center gap-10 text-center md:flex-row md:text-left"
      :class="
        iconAlign === 'left' ? 'flex-col' : 'flex-col-reverse sm:flex-col'
      "
    >
      <div
        v-if="iconVariant && iconAlign === 'left'"
        class="z-1 mb-10 mr-0 md:mb-0 md:mr-20"
        :class="iconClass"
      >
        <Icon :name="props.iconVariant" />
      </div>
      <div>
        <Headline
          v-if="description"
          :text="description"
          variant="xlarge"
          level="h3"
          headline-class="font-euclid mb-10 text-white"
        ></Headline>
        <StrapiButton
          v-if="button"
          class="m-auto w-full justify-center sm:w-max sm:justify-start md:m-0"
          :variant="button.variant"
          :text="button.text"
          :url="button.url"
          :new-tab="button.newTab"
        ></StrapiButton>
      </div>
      <div
        v-if="iconVariant && iconAlign === 'right'"
        class="z-1 mb-10 ml-0 md:mb-0 md:ml-20"
      >
        <Icon :name="props.iconVariant" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed as vComputed, PropType } from "vue";
import { CtaProps } from "./types";
import StrapiButton from "~/components/StrapiButton/StrapiButton.vue";
import Headline from "~/components/Headline/Headline.vue";
import Icon from "~/components/Icon/Icon.vue";

const baseClass = "gt-Cta";

const props = defineProps({
  iconAlign: {
    type: String as PropType<CtaProps["iconAlign"]>,
    default: "left",
  },
  iconVariant: {
    type: String as PropType<CtaProps["iconVariant"]>,
    default: "LeafV1Default",
  },
  iconClass: {
    type: String,
    default: undefined,
  },
  description: {
    type: String as PropType<CtaProps["description"]>,
    default: undefined,
  },
  button: {
    type: Object as PropType<CtaProps["button"]>,
    default: () => {},
  },
});
</script>
