/**
 * A utility function to debounce a given function.
 *
 * @param fn - The function to debounce. This function will be executed after the delay.
 * @param delay - The delay (in milliseconds) after which the function will be executed. Default is 150ms.
 *
 * @returns A debounced version of the provided function.
 *
 * The debounced function will only execute after the delay, and subsequent calls to the debounced function will cancel the previous call.
 * This is useful for preventing excessive function calls, such as during scrolling or input events.
 *
 * Example usage:
 * const debouncedFunc = useDebounce(() => { console.log('Executed!'); }, 500);
 * debouncedFunc(); // This will execute the function after 500ms.
 */
export const useDebounce = <T extends (...args: any[]) => void>(
  fn: T,
  delay = 150
) => {
  let timeout: ReturnType<typeof setTimeout>;

  // The debounced function
  const debouncedFn = (...args: Parameters<T>) => {
    // Clears the previous timeout if it's already set
    clearTimeout(timeout);

    // Sets a new timeout to invoke the function after the delay
    timeout = setTimeout(() => fn(...args), delay);
  };

  // Adding a cancel method to manually cancel the debounced function's execution
  debouncedFn.cancel = () => {
    clearTimeout(timeout); // Clears any pending timeout
  };

  // Returns the debounced function, which can be invoked with arguments
  return debouncedFn;
};
