/**
 * A utility function to copy text to the clipboard, excluding mailto links.
 *
 * If the provided text starts with "mailto:", it removes the "mailto:" prefix and copies only the email address.
 * If the text doesn't start with "mailto:", it copies the full text as it is.
 *
 * @param {string} text - The text to be copied to the clipboard.
 * @returns {Promise<boolean>} - A Promise that resolves to `true` if the text was copied successfully,
 *                                or `false` if there was an error.
 *
 * @example
 * const isCopied = await useClipboard("mailto:test@test.test");
 * console.log(isCopied); // true if successful, false otherwise
 */
export const useClipboard = async (text: string) => {
  // Check if the text starts with 'mailto:', and remove it if true
  if (text.startsWith("mailto:")) {
    text = text.slice(7); // Remove 'mailto:' from the beginning of the string
  }

  try {
    // Attempt to write the (possibly modified) text to the clipboard
    await navigator.clipboard.writeText(text);
    return true; // Successfully copied
  } catch (err) {
    console.error("Failed to copy:", err);
    return false; // Failed to copy
  }
};
