<template>
  <div
    class="higher-z-index fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center"
  >
    <div
      class="absolute left-0 top-0 h-full w-full bg-black/50"
      @click="!downloading && emit('closed')"
    ></div>
    <div
      id="dialog"
      class="bg-black-700 tall:h-3/5 small:h-4/5 z-50 flex h-full items-center justify-center p-3 sm:h-2/4 md:bg-transparent"
      :class="{ 'w-full': downloading }"
    >
      <div
        class="bg-black-500 h-full w-full max-w-screen-lg shadow-lg 2xl:max-w-screen-lg"
      >
        <div class="flex h-full">
          <!-- Left side with the image -->
          <div
            v-if="modalStep === 'first' && !downloading"
            class="hidden h-full w-full md:block md:w-4/5"
          >
            <Image
              v-bind="getBannerData?.attributes?.bannerImage?.data?.attributes"
              prefered-size="small"
              width="100%"
              height="100%"
            />
          </div>
          <!-- Right side -->
          <div
            :class="{
              'w-full md:w-3/5':
                !downloading &&
                bannerType !== BannerTypes.DownloadPresentationBanner,
              'md:w-full':
                downloading ||
                bannerType !== BannerTypes.DownloadPresentationBanner,
            }"
            class="relative flex w-full flex-col overflow-y-auto p-6"
          >
            <div class="mb-4 flex justify-between">
              <div class="bg-black-700 flex items-center px-3 py-2">
                <Icon name="PresentationIcon" />
                <Headline
                  v-if="getBannerData?.attributes?.subtitle"
                  :text="getBannerData.attributes.subtitle"
                  variant="bodyXsmall"
                  headline-class="font-mierb font-semibold uppercase ml-2.5 text-white"
                />
              </div>
              <button
                class="flex h-6 w-6 items-center justify-center rounded-full border border-white text-white"
                @click="emit('closed')"
              >
                <Icon name="close" icon-color="white" size="small" />
              </button>
            </div>
            <span class="border-black-400 w-full border" />
            <div
              :class="{
                'text-center':
                  bannerType === BannerTypes.DownloadSuccessfulBanner,
              }"
            >
              <div
                v-if="modalStep === 'first'"
                class="mt-8 flex flex-col justify-center normal-case"
              >
                <Headline
                  v-if="getBannerData?.attributes?.title"
                  :text="getBannerData.attributes.title"
                  level="h2"
                  headline-class="font-euclid font-normal text-white !text-xsmall md:!text-small"
                />
                <p
                  v-if="getBannerData?.attributes?.description"
                  v-dompurify-html="getBannerData.attributes.description"
                  class="font-mierb text-bodyLarge mt-8 font-normal leading-5 tracking-normal text-white"
                ></p>
                <HubspotForm
                  v-if="getBannerData?.attributes?.form"
                  :hubspot="getBannerData.attributes.form"
                  class="custom-class-hubspot"
                  @download-presentation="startDownloadingPresentation"
                />
              </div>
            </div>
            <div
              v-if="downloading"
              class="lg-h-[90%] bg-black-500 absolute bottom-[10%] z-50 flex h-[70%] w-full items-center justify-center"
            >
              <!-- Loader and "Downloading Presentation..." message -->
              <div class="flex flex-col items-center justify-center gap-7">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7a794907-3cc2-4f91-ae2b-665f94e1b4b6?"
                  class="aspect-square h-10 w-10 animate-spin overflow-hidden object-contain object-center"
                  alt="loader"
                />
                <Headline
                  text="Downloading presentation..."
                  level="h2"
                  headline-class="font-euclid font-normal text-white !text-xsmall md:!text-small normal-case"
                />
              </div>
            </div>
            <div
              v-if="modalStep === 'second'"
              class="relative flex h-full flex-col font-normal normal-case"
            >
              <div class="flex flex-col items-center text-center">
                <Icon
                  v-if="bannerType === BannerTypes.DownloadSuccessfulBanner"
                  class="mb-7 mt-5 w-full sm:w-auto"
                  alt="TickCircleIcon"
                  name="TickCircleIcon"
                />
                <Headline
                  v-if="
                    getBannerData?.attributes?.title &&
                    bannerType === BannerTypes.DownloadSuccessfulBanner
                  "
                  :text="getBannerData.attributes.title"
                  level="h2"
                  headline-class="font-euclid font-normal text-white !text-xsmall md:!text-small"
                />
                <p
                  v-if="
                    getBannerData?.attributes?.description &&
                    bannerType === BannerTypes.DownloadSuccessfulBanner
                  "
                  v-dompurify-html="getBannerData.attributes.description"
                  class="font-mierb text-bodyLarge mt-8 line-clamp-5 text-white"
                ></p>
                <span
                  v-if="bannerType === BannerTypes.DownloadSuccessfulBanner"
                  class="border-black-400 my-8 w-full border"
                />
              </div>
              <VideoTestimonials
                v-if="bannerType === 'WatchWebinarBanner'"
                :video="getBannerData?.attributes?.video"
              />

              <StrapiButton
                v-if="bannerType === BannerTypes.WatchWebinarBanner"
                text="DOWNLOAD WEBINAR PRESENTATION"
                variant="ghost"
                compact
                class="font-mierb mt-16 flex w-full cursor-pointer justify-end py-4"
                icon="download"
                @click="startDownloadingPresentation"
              />
              <span
                v-if="bannerType === BannerTypes.WatchWebinarBanner"
                class="border-black-400 mb-7 w-full border"
              />

              <div class="font-mierb flex w-full flex-col">
                <Headline
                  v-if="
                    getBannerData?.attributes?.downloadPresentationList
                      ?.headline
                  "
                  level="h2"
                  class="font-euclid text-white"
                  v-bind="
                    getBannerData.attributes.downloadPresentationList.headline
                  "
                />
                <WraplinkList
                  v-if="
                    getBannerData?.attributes?.downloadPresentationList
                      ?.wrapLinks
                  "
                  class="custom-class-wraplink mt-6"
                  :wrap-links="
                    getBannerData.attributes.downloadPresentationList.wrapLinks
                  "
                />
                <div v-if="!downloading" class="flex w-full justify-end">
                  <StrapiButton
                    text="CLOSE"
                    variant="primary"
                    compact
                    class="flex-end font-mierb my-8 flex cursor-pointer py-4"
                    @click="emit('closed')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref as vRef, onMounted as vOnMounted } from "vue";
import { useRoute as vUseRoute } from "vue-router";
import Icon from "~/components/Icon/Icon.vue";
import { Banner as BannerType } from "~/components/Banner/types";
import Image from "~/components/Image/Image.vue";
import { wraperListInterface } from "~/components/Wraplink/types";
import HubspotForm from "~/components/HubspotForm/HubspotForm.vue";
import { Button } from "~/components/StrapiButton/types";

const props = defineProps({
  bannerData: {
    // eslint-disable-next-line no-use-before-define
    type: Array as PropType<DownloadPresentation[]>,
    default: () => [],
  },
  bannerState: {
    type: String,
    default: "DownloadPresentationBanner",
  },
  file: {
    type: (Object as PropType<Button["file"]>) || String,
    default: undefined,
  },
  fileUrl: {
    type: String,
    default: undefined,
  },
  overrideIndex: {
    type: Boolean,
    default: false,
  },
});

const { strapiBaseUrl } = useRuntimeConfig().public;

const route = vUseRoute();
const banner = ref<BannerType>();
const downloading = ref(false);
const modalStep = vRef<"first" | "second">("first");
const bannerType = ref<string>(props.bannerState);
let scrollEventListener: EventListener;

const BannerTypes = {
  DownloadPresentationBanner: "DownloadPresentationBanner",
  DownloadSuccessfulBanner: "DownloadSuccessfulBanner",
  WatchWebinarBanner: "WatchWebinarBanner",
  FreeChapterBanner: "FreeChapterBanner",
};

type DownloadPresentation = {
  attributes: {
    bannerImage: {
      data: {
        attributes: {};
      };
    };
    title: string;
    subtitle: string;
    description: string;
    buttons: {}[];
    form: {};
    downloadPresentationList: {
      headline: {};
      wrapLinks: wraperListInterface[];
    };
    video: {
      description?: string;
      poster?: {};
      title?: string;
      videoUrl?: string;
    };
    file: {};
  };
};

const emit = defineEmits(["closed"]);
const startDownloadingPresentation = async () => {
  // Simulate a second delay before showing different content
  if (
    props.bannerState === BannerTypes.DownloadPresentationBanner ||
    props.bannerState === BannerTypes.FreeChapterBanner
  ) {
    let pdfFile;

    if (props.fileUrl) {
      pdfFile = props.fileUrl;
    } else if (props.file) {
      pdfFile = getBannerData.value?.attributes.file.data.attributes;
    }

    downloading.value = true;

    if (pdfFile) {
      // Make an API call to fetch the PDF file
      const response = await fetch(strapiBaseUrl + (pdfFile.url || pdfFile));

      if (!response.ok) {
        throw new Error("Failed to fetch PDF");
      }

      // Function to trigger the download
      const triggerDownload = () => {
        const link = document.createElement("a");
        link.href = response.url;
        link.download = pdfFile.name || "GenTwo Persentation"; // Use the actual file name
        link.click();

        // Reset downloading flag
        downloading.value = false;

        // Set the banner type after the download
        bannerType.value = BannerTypes.DownloadSuccessfulBanner;
      };

      // Call triggerDownload after a delay of 1500 milliseconds (1.5 seconds)
      setTimeout(triggerDownload, 1500);
    }
  }
  modalStep.value = "second";

  // Set modal step
};

// Add this script to apply the overflow-hidden class
vOnMounted(() => {
  const headerEl = document.getElementById("__nuxt");
  document.documentElement.classList.add("overflow-hidden");
  document.body.classList.add("overflow-hidden");

  if (!props.overrideIndex) {
    headerEl?.classList?.add("sticky-index");
  }
});

onBeforeUnmount(() => {
  const headerEl = document.getElementById("__nuxt");
  document.documentElement.classList.remove("overflow-hidden");
  document.body.classList.remove("overflow-hidden");
  if (!props.overrideIndex) {
    headerEl?.classList?.remove("sticky-index");
  }
});

const getBannerData = computed(() => {
  return props.bannerData.find(
    (item) => item.attributes.type === bannerType.value
  );
});
</script>
<style lang="scss">
.custom-class-wraplink {
  a {
    display: flex;
    flex-direction: row;
    padding: 0 0 0 16px;
    @apply bg-black-700;
    &:hover {
      @apply bg-black-700;
    }
  }
  h3 {
    @apply font-semibold;
  }
}

.custom-class-hubspot {
  .input .error {
    border-color: #e42e3b !important;
  }
  .hs-input {
    background-color: #1d2021 !important;
    border-color: #1d2021 !important;
  }
  .hs-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #1d2021 inset !important;
  }

  .hs-form-field {
    color: white !important;
  }
  .legal-consent-container {
    text-transform: none;
    font-weight: normal;
  }

  .hs-form-booleancheckbox {
    letter-spacing: normal;
    font-size: 14px;
  }

  .hs-richtext {
    margin-top: 24px;
    color: #c0c2c3;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: normal;
    &:last-child {
      margin-bottom: 32px;
    }
  }

  fieldset {
    font-size: 16px;
  }

  .no-list {
    margin-top: 10px;
  }

  .hs-error-msg {
    text-transform: none;
  }
  .hs-error-msgs {
    color: #f2545b;
    text-transform: none;
    font-size: 16px;
    letter-spacing: normal;
  }
  input[type="checkbox"] {
    appearance: none;
    background-color: #a2a4a5 !important;
    width: 23px !important;
    height: 23px;
  }
  input[type="checkbox"]:checked {
    appearance: auto;
  }
  text-transform: capitalize;
  font-weight: 500;
}

.higher-z-index {
  z-index: 99999 !important;
  contain: none !important;
}

.overflow-hidden #index .sticky-index .sticky,
.overflow-hidden .sticky-index .sticky {
  z-index: unset !important;
}
.overflow-hidden .sticky.backdrop-blur-sm {
  backdrop-filter: none !important;
}
</style>
