/**
 * A utility function to smoothly scroll to an element with a given selector in the document.
 *
 * @param {string} selector - The selector of the target element that you want to scroll to.
 * @param {number} offset - The offset (in pixels) from the top of the page to adjust the scroll position. Default is 0.
 * @param {number} behavior - The behavior of the scroll. Default is 'smooth'.
 *
 * @returns {void} This function does not return a value, it triggers the scroll action.
 *
 * The function will locate the element with the specified ID and scroll the page to that element's position.
 * It adjusts the scroll position by the provided offset, ensuring the target element is in view.
 *
 * Example usage:
 * useScrollIntoView("section-id", 140, 'auto');
 */
export const useScrollIntoView = (
  selector: string,
  offset = 0,
  behavior: ScrollBehavior = "smooth"
): void => {
  const element = document.querySelector(selector);
  if (element) {
    // Get the target position for scroll, including the offset
    const targetPosition =
      element.getBoundingClientRect().top + window.scrollY - offset;

    // Smooth scroll logic
    window.scrollTo({
      top: targetPosition,
      behavior,
    });
  } else {
    // If no element is found, log a warning
    console.warn(`No element found with the selector: "${selector}"`);
  }
};
