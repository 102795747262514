<template>
  <VueFinalModal
    v-model="localModelValue"
    class="bg-black-500 md:bg-black-950 flex items-center justify-center"
    :content-class="contentClass"
    content-transition="vfm-fade"
    hide-overlay
    overlay-transition="vfm-fade"
    @closed="emit('close-modal')"
  >
    <slot />

    <button
      v-if="showCloseButton"
      class="absolute right-4 top-4 rounded-lg border-0 px-2 text-white"
      @click="emit('close-modal')"
    >
      <Icon name="close" />
    </button>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from "vue-final-modal";

import { computed as vComputed } from "vue";
import Icon from "~/components/Icon/Icon.vue";

const props = defineProps({
  modelValue: { type: Boolean, default: false },
  showCloseButton: { type: Boolean, default: true },
  contentClass: { type: String, default: undefined },
});

const localModelValue = vComputed(() => props.modelValue);

const emit = defineEmits<{
  (e: "close-modal"): void;
}>();
</script>
