export const hubspotCSS = `
form {
    width: 100%;
    margin-top: 10px;
  }

  .hs-fieldtype-text {
    padding-top: 10px;
    color: #A2A4A5;
    background-color: transparent;
  }
  
  .input {
    margin-top: 10px;
    padding: 0;
    color: white;
  }
  
  legend {
    color: white;
    font-size: 16px;
    font-weight: 500;
  }

  .form-columns-2 {
    display: flex;
    align-items: baseline;
  }

  .hs-form-field {
    padding: 0;
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    .form-columns-2 {
      flex-direction: column;
    }
    .hs-form-field {
      width: 100%!important;
    }
  }

  .input .hs-input:focus {
    outline: none;
  }
  
  .input .hs-input {
    height: 50px;
    border: 1px solid;
    border-color: #A2A4A5;
    background-color: #101114;
    -webkit-background: #101114;
    -moz-background: #101114;
    width: 100%;
  }
  
  .input .error {
    border-color: #E42E3B;
  }
  
  .hs-error-msg {
    color: #E42E3B;
  }

  .hs-button {
    color: #0C0D0E;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    border: 1px solid;
    border-color: #A2A4A5;
    background-color: white;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    margin: 16px 0;
  }

  select {
    background: white;
    -webkit-appearance: white;
    padding: 10px;
    cursor: pointer;
  }

  .hs-form-booleancheckbox-display {
    display: flex;
    align-items: center;
  }

  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #101114 inset !important;
    -webkit-text-fill-color: white !important;
  }

  .hs-form-checkbox label {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;
