import {
  headlineType,
  iconType,
  buttonType,
  frameType,
  CardStyle,
} from "~/components/StrapiCard/types";

export const defaultFrameProps: frameType = {
  variant: "map",
  url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d337.7760656845815!2d8.531830487077526!3d47.368848456348815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900a071e8f24ff%3A0x6867c75b3f405ac6!2sGENTWO%20AG!5e0!3m2!1sen!2suk!4v1618311593589!5m2!1sen!2suk",
  location: undefined,
  phone: undefined,
  email: undefined,
};

export const defaultHeadlineProps: headlineType = {
  title: "",
  titleVariant: "",
  subtitle: "",
  subtitleVariant: "",
  description: "",
  descriptionVariant: "",
  descriptionNumber: undefined,
};

export const defaultIconProps: iconType = {
  name: "",
  size: "base",
};

export const defaultButtonProps: buttonType = {
  url: undefined,
  text: undefined,
  newTab: false,
};

export const variantsOfCard = [
  "teamCard",
  "teamCardNewVersion",
  "digitalInvestments",
  "digitalInvestmentsAssets",
  "bankValute",
  "stepsForInvestmentProduct",
  "reachPlus",
  "plainCard",
  "ourCoreValues",
  "statisticsCard",
  "benefitsWorkingWithUs",
  "locationCard",
] as const;

export const targetLink = ["_self", "_blank"] as const;

export const direction = ["column", "row"] as const;

export const cardStyles = (stepsDirectionVariant?: string): CardStyle => {
  return {
    teamCard: {
      isClickable: "cursor-pointer hover:bg-black-500",
      parentDiv:
        "group w-full bg-black-600 py-5 px-[26px] lg:px-5 text-white flex items-center flex-col justify-start text-center ",
      image: "rounded-[50%]",
      title: "py-4 font-euclid font-medium",
      description: "font-mierb font-medium",
    },
    teamCardNewVersion: {
      isClickable: "cursor-pointer",
      parentDiv:
        "group w-full 2xl:h-80 xl:h-96 bg-black-600 text-white flex items-center flex-col justify-start text-center relative",
      image: "object-center-top",
      title: "!font-medium !text-bodyLarge w-4/5 font-euclid pb-1",
      description: "text-capitalXSmall font-mierb !font-medium leading-4",
    },
    digitalInvestments: {
      isClickable: "cursor-pointer bg-black-600 hover:bg-black-500",
      parentDiv:
        "lg:w-1/2 w-full text-white p-6 flex items-start flex-col justify-start",
      title: "font-semibold pb-2",
      description: "font-mierb",
      icon: "text-transparent",
    },
    digitalInvestmentsAssets: {
      isClickable: "cursor-pointer hover:bg-black-600",
      parentDiv:
        "w-full text-white p-6 flex items-start flex-col justify-start",
      title: "pb-4 pt-5 font-mierb font-semibold",
      description: "font-mierb",
    },
    bankValute: {
      isClickable: "cursor-pointer lg:hover:bg-white lg:hover:text-black-600",
      parentDiv:
        "group w-full text-white p-6 flex items-baseline lg:items-center flex-row lg:flex-col",
      icon: "group-hover:text-black-600 mr-4 lg:mr-0 h-full text-white",
      title: "lg:py-4 font-mierb h-full",
      description: "lg:block hidden font-medium font-mierb",
    },
    stepsForInvestmentProduct: {
      isClickable: "cursor-pointer hover:bg-black-600",
      parentDiv: `w-full xl:px-6 text-white flex xl:flex-col items-start ${
        stepsDirectionVariant === "row"
          ? "xl:items-center pt-12"
          : "xl:items-start"
      } justify-start`,
      icon: "text-black-600 m-2 xl:m-5",
      title: `font-semibold font-mierb pb-2 xl:pl-0 pt-0 xl:pb-6 ${
        stepsDirectionVariant === "row" ? "xl:pt-12" : "xl:pt-4"
      }`,
      description: "flex font-medium font-mierb",
      subtitle: `hidden text-black-200 ${
        stepsDirectionVariant === "column" && "xl:flex "
      }`,
    },
    reachPlus: {
      isClickable: "cursor-pointer hover:bg-black-600",
      parentDiv: "w-full text-white flex items-start flex-col justify-start",
      title: "pt-2.5 lg:pt-6 font-mierb font-normal",
      description: "text-bodyXLarge font-mierb pt-6",
    },
    plainCard: {
      isClickable: "cursor-pointer bg-black-600 hover:bg-black-500",
      parentDiv:
        "p-6 text-white flex justify-between items-start flex-col w-full h-full",
      title: "font-semibold font-mierb",
      icon: "text-transparent m-0 mb-5",
      description: "pt-4 font-mierb text-bodyLarge",
    },
    ourCoreValues: {
      isClickable: "cursor-pointer hover:bg-black-600",
      parentDiv:
        "py-5 pr-6 text-white flex items-start flex-col justify-center w-full",
      image: "w-full h-full rounded-[50%] max-w-[200px] max-h-[200px]",
      icon: "text-transparent m-0",
      title: "py-4",
    },
    statisticsCard: {
      isClickable: "cursor-pointer hover:bg-black-600",
      parentDiv:
        "p-5 text-white flex items-start flex-col justify-between w-full",
      image: "w-full h-full rounded-[50%] max-w-[200px] max-h-[200px]",
      icon: "text-transparent",
      title: "pt-10 font-normal",
    },
    benefitsWorkingWithUs: {
      isClickable: "cursor-pointer hover:bg-black-600",
      parentDiv:
        "w-full p-5 gap-8 md:gap-0 text-white flex lg:items-start flex-col justify-between",
      icon: "text-transparent",
      title: "lg:pt-9 font-euclid font-medium",
    },
    locationCard: {
      parentDiv:
        "w-full bg-black-600 pb-10 text-white flex items-start flex-col justify-center",
      title: "py-5 px-8 text-xsmall font-medium font-euclid",
    },
  };
};

export const cardListsClasses = (stepsDirectionVariant?: string): CardStyle => {
  return {
    teamCard: {
      parentOfCardsList:
        "grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 w-full",
    },
    teamCardNewVersion: {
      parentOfCardsList:
        "grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 w-full",
    },
    benefitsWorkingWithUs: {
      parentOfCardsList:
        "grid grid-cols-1 w-full lg:grid-cols-4 gap-y-4 lg:gap-y-6 lg:gap-x-[22px]",
    },
    ourCoreValues: {
      parentOfCardsList:
        "flex w-full justify-beetwen bg-transparent items-start lg:flex-row flex-col",
    },
    locationCard: {
      parentOfCardsList: "grid grid-cols-1 lg:grid-cols-2 gap-5",
    },
    plainCard: {
      parentOfCardsList:
        "hidden md:grid md:grid-cols-2 items-start md:gap-x-5 md:gap-y-6",
      positionOfPaginationDots: "left",
    },
    digitalInvestments: {
      parentOfCardsList: "flex lg:flex-row flex-col gap-6",
    },
    digitalInvestmentsAssets: {
      parentOfCardsList: "grid grid-cols-1 lg:grid-cols-2 grid-col-1 gap-6",
    },
    bankValute: {
      parentOfCardsList:
        "grid grid-cols-1 lg:grid-cols-4 gap-x-4 gap-y-4 lg:gap-y-10",
    },
    stepsForInvestmentProduct: {
      parentOfCardsList: `w-full flex flex-col ${
        stepsDirectionVariant === "row" && "xl:flex-row"
      }`,
    },
    statisticsCard: {
      parentOfCardsList:
        "w-full grid grid-cols-1 lg:grid-cols-5 gap-5 items-start",
    },
    reachPlus: {
      parentOfCardsList:
        "w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-5 mt-4 sm:mt-0",
    },
  };
};
