<template>
  <footer
    class="bg-black-700 mt-52"
    :class="{ 'mt-8 md:mt-32': layout === 'releases' }"
  >
    <div class="container mx-auto">
      <GridLayout variant="fullBleed" class="h-full p-5 md:p-10">
        <div v-if="layout === 'default'" class="col-span-full">
          <div class="flex w-full flex-col gap-9 lg:flex-row lg:gap-4">
            <div class="mb-5 flex-1 md:m-0">
              <div class="relative">
                <div
                  class="relative z-10 flex h-full w-full flex-col items-start bg-black p-5 text-white lg:ml-5 lg:w-2/3 lg:bg-black lg:p-10"
                >
                  <Headline
                    level="h5"
                    variant="small"
                    text="Be one step ahead of everyone and write your own success story. Now."
                    class="pt-5 font-semibold"
                  />
                  <Headline
                    level="h5"
                    variant="base"
                    text="Don't miss the opportunity to be part of our community and receive exclusive content and offers."
                    headline-class="text-bodyLarge font-medium font-mierb text-black-100 pt-5"
                  />
                  <StrapiButtonList
                    :buttons="options?.attributes?.footerSignUpLinks"
                  />
                </div>
                <Cta
                  class="absolute left-0 top-0 -mt-72 w-5/6 md:-mt-60 md:w-full"
                  layout="fullBleed"
                  icon-align="left"
                  icon-variant="LeafV6Large"
                  icon-class="w-full"
                />
              </div>
            </div>
            <div class="flex flex-1 items-start">
              <div
                class="grid w-full grid-cols-1 gap-y-10 text-white md:grid-cols-3 md:gap-x-8 md:gap-y-16 lg:grid-cols-2"
              >
                <ul
                  v-for="(value, key) in topItems"
                  :key="key"
                  class="text-bodyXLarge flex flex-col gap-4"
                >
                  <Headline
                    level="h5"
                    variant="xsmall"
                    :text="value.title"
                    class="font-semibold capitalize"
                  />
                  <li v-for="link in value.items" :key="link.url">
                    <NuxtLink :to="link.url">
                      {{ link.text }}
                    </NuxtLink>
                  </li>
                </ul>
                <div>
                  <Image src="./award.png" width="96px" height="112px" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="layout === 'default'"
          class="border-black-400 col-span-full my-5 border-t md:my-8"
        ></div>
        <div
          class="text-black-200 col-span-full flex w-full flex-col justify-between gap-5 lg:flex-row lg:gap-0"
        ></div>
        <div class="col-span-full w-full md:mt-12">
          <div
            class="flex w-full flex-col pb-6 sm:flex-row"
            :class="{ 'inline-grid pb-3 md:flex': layout === 'releases' }"
          >
            <div
              class="w-full grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-x-14 gap-y-8 pb-10 text-white"
            >
              <ul
                v-for="(value, key) in bottomItems"
                :key="key"
                class="text-bodyXLarge flex flex-col gap-4"
              >
                <Headline
                  level="h5"
                  variant="xsmall"
                  :text="value.title"
                  class="font-semibold capitalize"
                />
                <li v-for="link in value.items" :key="link.url">
                  <NuxtLink :to="link.url">
                    {{ link.text }}
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </div>
          <div class="pb-22 mt-4 flex justify-between text-white">
            <ShareList
              :icon-list="[
                {
                  icon: 'Linkedin',
                  variant: 'redirect',
                  iconSize: 'default',
                  linkTo: 'https://www.linkedin.com/company/gentwo',
                },
              ]"
              custom-classes="gap-2 !justify-start sm:gap-5"
            />
            <Headline
              level="h4"
              variant="base"
              text="All rights reserved"
              class="whitespace-nowrap"
              >© {{ currentYear }}</Headline
            >
          </div>
        </div>
      </GridLayout>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { computed as vComputed, PropType, ref as vRef } from "vue";
import { Post } from "../PostCard/types";
import { terms } from "./const";
import Cta from "~/components/CTA/Cta.vue";
import Headline from "~/components/Headline/Headline.vue";
import { LinkInfo, FooterItems } from "~/components/Footer/types";
import Icon from "~/components/Icon/Icon.vue";
import GridLayout from "~/components/Grid/GridLayout.vue";
import ShareList from "~/components/ShareList/ShareList.vue";

const { find } = useStrapi4();
const topItems = vRef([]);
const bottomItems = vRef([]);

const [{ data: options }, { data: footers }] = await Promise.all([
  find("option", { populate: "*" }),
  find("footers", { populate: "*" }),
]);

const extractAttributesByPosition = (
  items: { attributes: { position: string } },
  position: "top" | "bottom"
) =>
  items
    ?.filter(
      ({ attributes }) =>
        attributes.position === position ||
        (!attributes.position && position === "top")
    )
    .map(({ attributes }) => attributes) || [];

if (footers) {
  topItems.value = extractAttributesByPosition(footers, "top");
  bottomItems.value = extractAttributesByPosition(footers, "bottom");
}

const props = defineProps({
  footerItems: {
    type: Object as PropType<FooterItems[]>,
    default: () => {},
  },
  termItems: {
    type: Array as PropType<LinkInfo[]>,
    default: () => terms,
  },
  layout: {
    type: String,
    default: "default",
    validator: (value: string) => {
      return ["default", "releases"].includes(value);
    },
  },
});

const currentYear = vComputed(() => new Date().getFullYear());
</script>
<style lang="scss">
.hubspot-style {
  label {
    display: none;
  }
  .input {
    padding: 0;
  }
  .hs-error-msg {
    display: flex;
  }
}
</style>
