<template>
  <div
    class="sticky top-0 z-50 mx-auto w-full transition-all duration-150"
    :class="{
      'bg-black-800/70 backdrop-blur-sm': scrollY > 10 && !isMobileNavOpen,
    }"
  >
    <div class="container mx-auto p-0 lg:px-3.5">
      <GridLayout
        variant="fullBleed"
        :class="[
          'navigation text-white transition-all md:items-center lg:h-32',
          layout === 'releases' && 'gird-cols-2 gap-0 lg:grid-cols-2 lg:gap-0',
        ]"
      >
        <nav role="navigation" class="hidden lg:contents">
          <div class="col-start-1 col-end-3">
            <NuxtLink
              to="/"
              title="Home page"
              aria-label="Home page"
              class="no-link-active-styling w-full"
            >
              <Icon class="w-[140px] shrink-0" name="LogoIcon" size="large" />
            </NuxtLink>
          </div>
          <StrapiButton
            v-if="options.data.attributes.linkToPro && layout === 'releases'"
            v-bind="options.data.attributes.linkToPro"
            class="text-bodyLarge col-start-13 col-end-13 cursor-pointer"
          />
          <div
            v-if="layout !== 'releases'"
            class="col-start-3 col-end-13 flex h-full items-center justify-between"
          >
            <ul
              v-if="layout === 'default'"
              class="font-mierb text-bodyLarge m-0 flex h-full content-center items-center p-0"
            >
              <li
                v-for="item in items"
                :key="item.title"
                class="group/item flex h-full w-full cursor-pointer items-center px-2 first:-ml-4 first:pl-4 xl:px-4"
              >
                <button class="relative flex flex-col">
                  <component
                    :is="item.path ? 'a' : 'p'"
                    :href="item.path"
                    target="_self"
                    class="font-mierb text-bodyLarge lg:text-bodyXLarge whitespace-nowrap font-semibold"
                  >
                    {{ item.title }}
                  </component>
                  <span
                    class="bg-mango h-0.5 w-0 pb-0.5 transition-all duration-150 group-hover/item:w-full"
                  ></span>
                </button>
                <div
                  v-if="item.items && item.items.length"
                  class="hidden-block md:max-xl bg-black-600 absolute left-0 top-32 z-10 col-start-1 col-end-13 hidden h-0 w-full cursor-default gap-5 py-16 transition-all group-hover/item:flex group-hover/item:h-max lg:gap-14"
                >
                  <div class="container mx-auto flex">
                    <GridLayout
                      variant="fullBleed"
                      class="navigation text-white transition-all md:items-center"
                    >
                      <div
                        class="container col-span-full mx-auto p-0 lg:px-3.5"
                        :class="item.ctaBanner ? 'basis-4/6' : 'basis-full'"
                      >
                        <ul class="flex flex-wrap gap-2 xl:gap-6">
                          <li
                            v-for="subMenu in item.items"
                            :key="subMenu.id"
                            class="flex flex-col gap-4 lg:basis-1/4 xl:basis-1/6"
                          >
                            <Headline
                              :text="subMenu.title"
                              variant="capitalSmall"
                              level="h3"
                              headline-class="font-euclid text-black-300"
                            />
                            <ul
                              class="font-mierb text-bodyXLarge flex flex-col gap-5"
                            >
                              <NuxtLink
                                v-for="sub in subMenu.subItems"
                                :key="sub"
                                :target="sub.newTab ? '_blank' : '_self'"
                                :to="sub.path"
                                class="sub-item flex cursor-pointer items-end"
                              >
                                {{ sub.title }}
                                <Icon
                                  v-if="sub?.newTab"
                                  name="UpArrow"
                                  size="default"
                                  class="ml-2"
                                />
                              </NuxtLink>
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div
                        v-if="item.ctaBanner"
                        class="flex basis-1/3 flex-col gap-8"
                      >
                        <div class="isolate h-max">
                          <Icon name="LeafV2Default" class="h-20 w-24" />
                        </div>
                        <Headline
                          v-if="item.ctaBanner"
                          :text="item.ctaBanner?.description"
                          variant="xsmall"
                          level="h6"
                          headline-class="font-euclid text-white"
                        />
                        <div class="flex gap-4">
                          <StrapiButton
                            v-bind="item.ctaBanner.button"
                          ></StrapiButton>
                          <StrapiButton
                            v-if="item.ctaBanner"
                            variant="secondary"
                            text="TBD"
                            compact
                            url="https://gentwo.com"
                          ></StrapiButton>
                        </div>
                      </div>
                    </GridLayout>
                  </div>
                </div>
              </li>
            </ul>
            <div class="relative flex items-center gap-2 lg:-mt-1.5 lg:gap-2">
              <StrapiButton
                v-for="link in options.data.attributes.headerCtaLinks"
                :key="link"
                v-bind="link"
                override-index
              />
              <StrapiButton
                v-if="options.data.attributes.linkToPro"
                v-bind="options.data.attributes.linkToPro"
                class="text-bodyLarge cursor-pointer"
                override-index
              />
            </div>
          </div>
        </nav>
        <!-- overlay nav menu -->
        <nav role="navigation" class="contents lg:hidden">
          <div
            class="col-span-full flex justify-between py-5"
            :class="{ 'items-baseline': layout === 'releases' }"
          >
            <NuxtLink
              to="/"
              title="Home page"
              aria-label="Home page"
              class="no-link-active-styling col-span-2"
            >
              <Icon name="LogoIcon" class="w-36" size="large" />
            </NuxtLink>
            <button v-if="layout !== 'releases'" @click="toggleMobileNav()">
              <span class="block h-0.5 w-6 bg-white"></span>
              <span class="my-1 block h-0.5 w-6 bg-white"></span>
              <span class="block h-0.5 w-6 bg-white"></span>
            </button>
            <StrapiButton
              v-if="options.data.attributes.linkToPro && layout === 'releases'"
              v-bind="options.data.attributes.linkToPro"
              class="text-bodyLarge cursor-pointer"
            />
          </div>
          <Transition name="fade-left">
            <div
              v-show="isMobileNavOpen"
              class="bg-black-600 absolute left-0 top-0 z-50 flex h-[calc(100vh+3rem)] w-full flex-col justify-between overflow-y-scroll px-4"
            >
              <div class="container mx-auto flex h-max flex-col">
                <div class="col-span-full flex justify-between pb-10 pt-5">
                  <NuxtLink
                    to="/"
                    title="Home page"
                    aria-label="Home page"
                    class="no-link-active-styling col-span-2"
                    @click="toggleMobileNav()"
                  >
                    <Icon name="LogoIcon" class="w-36" size="large" />
                  </NuxtLink>
                  <button @click="toggleMobileNav()">
                    <Icon size="large" name="cancel" />
                  </button>
                </div>
                <div
                  v-for="item in items"
                  :key="item.title"
                  class="border-b-black-500 flex h-max cursor-pointer flex-col pb-6 transition-all"
                  :class="{ 'mb-6 border-b': item.isActive }"
                  @click="toggleItem(item)"
                >
                  <div class="flex items-center justify-between">
                    <component
                      :is="item.path ? 'a' : 'p'"
                      :href="item.path"
                      class="font-mierb text-bodyXLarge"
                    >
                      {{ item.title }}
                    </component>

                    <button v-if="item.items && item.items.length" class="pl-2">
                      <Icon
                        style="font-size: 31px"
                        :name="item.isActive ? 'expand_less' : 'expand_more'"
                      />
                    </button>
                  </div>
                  <div
                    class="bg-black-500 mt-1.5 h-px w-full"
                    :class="{
                      'bg-mango': item.isActive,
                    }"
                  ></div>
                  <Transition name="slide-down">
                    <div
                      v-show="item.isActive"
                      v-if="item.items && item.items.length"
                      class="flex flex-col gap-5 pb-0 pt-5"
                    >
                      <div
                        v-for="subMenu in item.items"
                        :key="subMenu.title"
                        class="flex flex-col gap-3.5"
                      >
                        <Headline
                          :text="subMenu.title"
                          variant="capitalSmall"
                          level="h3"
                          headline-class="font-euclid text-black-300"
                        />
                        <ul
                          class="font-mierb lg:text-bodyXLarge flex flex-col gap-3 md:gap-5"
                        >
                          <li
                            v-for="(sub, index) in subMenu.subItems"
                            :key="index"
                            class="hover:text-black-50 flex cursor-pointer font-semibold"
                            @click.stop="toggleMobileNav()"
                          >
                            <NuxtLink
                              :target="sub.newTab ? '_blank' : '_self'"
                              :to="sub.path"
                              class="flex w-full items-end"
                            >
                              {{ sub.title }}
                              <Icon
                                v-if="sub?.newTab"
                                name="UpArrow"
                                size="default"
                                class="ml-2"
                              />
                            </NuxtLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
              <div
                class="bg-black-600 container mx-auto mb-12 flex w-full flex-col gap-2 py-5 lg:gap-4"
              >
                <StrapiButton
                  v-for="link in options.data.attributes.headerCtaLinks"
                  :key="link"
                  v-bind="link"
                  class="z-50 w-full justify-center"
                  override-index
                  @click="toggleMobileNav()"
                />
                <StrapiButton
                  v-if="options.data.attributes.linkToPro"
                  v-bind="options.data.attributes.linkToPro"
                  class="w-full justify-center"
                  override-index
                  @click="toggleMobileNav()"
                />
              </div>
            </div>
          </Transition>
        </nav>
      </GridLayout>
    </div>
    <div v-if="shouldShowIndicatorBar" class="h-0.5 w-full overflow-hidden">
      <div
        ref="scrollIndicatorBar"
        class="bg-mango h-full w-0 transition ease-in-out"
      ></div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useToggle, useWindowScroll } from "@vueuse/core";
import {
  ref as vRef,
  PropType,
  onBeforeMount as vOnBeforeMount,
  computed as vComputed,
  watch as vWatch,
  onUnmounted as vOnUnmounted,
  onMounted as vOnMounted,
} from "vue";
import { storeToRefs } from "pinia";
import { useRouter as vUseRouter, useRoute as vUseRoute } from "vue-router";
import { Post } from "../PostCard/types";

import Headline from "~/components/Headline/Headline.vue";
import GridLayout from "~/components/Grid/GridLayout.vue";
import StrapiButton from "~/components/StrapiButton/StrapiButton.vue";
import { menuItems } from "~/components/Header/const";
import { menuItem } from "~/components/Header/types";
import Icon from "~/components/Icon/Icon.vue";

defineProps({
  navLinks: {
    type: Object as PropType<menuItem[]>,
    default: () => menuItems,
  },
  layout: {
    type: String,
    default: "default",
    validator: (value: string) => {
      return ["default", "releases"].includes(value);
    },
  },
});
const router = vUseRouter();
const route = vUseRoute();
const [isMobileNavOpen, toggleMobileNav] = useToggle(false);
const scrollY = useWindowScroll().y;
const items = vRef();
const nuxtApp = useNuxtApp();
const scrollIndicatorBar = vRef<HTMLElement | null>(null);
let scrollEventListener: EventListener;

const { find } = useStrapi4();

const [{ data: options }, { data }] = await Promise.all([
  useAsyncData("options-header", () => find("option", { populate: "*" })),
  find("headers", {
    sort: ["id:asc"],
  }),
]);

items.value =
  data?.length > 0
    ? data.map((item: Post) => ({
        ...item.attributes,
        isActive: false,
      }))
    : menuItems;

vWatch(isMobileNavOpen, (newValue) => {
  if (newValue) {
    document.querySelector("body")?.classList.add("overflow-hidden");
  }
  if (!newValue) {
    document.querySelector("body")?.classList.remove("overflow-hidden");
    items.value = items.value.map((item: {}) => ({
      ...item,
      isActive: newValue,
    }));
  }
});

function updateScrollIndicatorWidth(el: HTMLElement, indicator: HTMLElement) {
  const windowHeight = window.innerHeight;
  const documentHeight = el.clientHeight - windowHeight;
  const scrollPercentage = (scrollY.value / documentHeight) * 100;
  if (scrollPercentage <= 110) {
    indicator.style.width = scrollPercentage + "%";
  }
}

function addScrollEventListener(
  mainElement: HTMLElement,
  scrollIndicator: HTMLElement
) {
  scrollEventListener = () => {
    updateScrollIndicatorWidth(mainElement, scrollIndicator);
  };
  window.addEventListener("scroll", scrollEventListener);
}

function removeScrollEventListener() {
  window.removeEventListener("scroll", scrollEventListener);
}

function indicatorScroll() {
  if (shouldShowIndicatorBar.value) {
    const mainElementOnView = document.querySelector("main");
    if (mainElementOnView && scrollIndicatorBar.value) {
      addScrollEventListener(mainElementOnView, scrollIndicatorBar.value);
    }
  } else {
    removeScrollEventListener();
  }
}

function toggleItem(item: menuItem, index?: number) {
  items.value.forEach((i: menuItem) => {
    if (i !== item) {
      i.isActive = false;
    }
  });

  item.isActive = !item.isActive;

  if (item.path) {
    router.push(item.path);
    toggleMobileNav();
  }
}

const shouldShowIndicatorBar = vComputed(() => {
  const slugContainer: string[] = [];
  const pageData =
    options?.value?.data?.attributes?.pagesToShowScrollIndicator?.data;
  if (!pageData) {
    return false;
  }
  pageData.map((option: { attributes: { slug: string } }) =>
    slugContainer.push(option.attributes.slug)
  );
  return slugContainer.some(
    (slug) => route.path.includes("/" + slug) && route.params.slug
  );
});

nuxtApp.hook("page:finish", () => indicatorScroll());

vOnMounted(() => indicatorScroll());

vOnUnmounted(() => {
  removeScrollEventListener();
});
</script>
<style lang="scss" scoped>
.fade-left-enter-active,
.fade-left-leave-active {
  transition: all 0.3s ease;
  transition-property: opacity, transform;
}
.fade-left-enter-from,
.fade-left-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}
.hidden-block::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 50px;
  top: -50px;
  left: 0;
}

.slide-down-enter-active {
  transition: all 0.2s ease-out;
}

.slide-down-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-down-enter-from,
.slide-down-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
