// Each entry added to `iconNames` should be created as a Vue component in Icons Folder.
export const iconNames = [
  "AssetRushLabel",
  "AuthLeaf",
  "CopyClipboard",
  "Dollar",
  "DoneAll",
  "DownloadIcon",
  "EnvelopeCheckIcon",
  "Etherium",
  "EyeIcon",
  "EyeIconClosed",
  "Facebook",
  "Instagram",
  "Linkedin",
  "LogoIcon",
  "NoContent",
  "NotFoundIllustrator",
  "PlayButton",
  "PresentationIcon",
  "Spinner",
  "TickCircleIcon",
  "Twitter",
  "UpArrow",
  "Youtube",
  "LeafV1Default",
  "LeafV2Default",
  "LeafV2Large",
  "LeafV3Default",
  "LeafV4Default",
  "LeafV4Large",
  "LeafV5Default",
  "LeafV6Default",
  "LeafV6Small",
  "LeafV6Large",
  "LeafV7Default",
];

// Each entry must be available in Google Material Icons to be rendered.
export const materialIconNames = [
  "expand_more",
  "expand_less",
  "navigate_next",
  "close",
  "cancel",
  "Search",
  "arrow_forward",
  "arrow_back",
  "arrow_upward",
  "arrow_outward",
  "forward",
  "work_history",
  "download",
  "all_inclusive",
  "bolt",
  "star",
  "lightbulb",
  "settings",
  "grid_view",
  "deployed_code",
  "add",
  "monitoring",
  "swap_vertical_circle",
  "show_chart",
  "change_history",
  "location_on",
  "app_registration",
  "person_apron",
  "language",
  "health_and_safety",
  "emergency",
  "currency_bitcoin",
  "phone_in_talk",
  "mail",
  "send",
  "visibility",
  "visibility_off",
  "data_check",
  "new_releases",
  "schedule",
  "history",
  "filter_alt",
  "filter_list",
  "play_circle",
  "play_arrow",
  "skip_next",
  "replay",
  "smart_display",
  "skip_previous",
  "wallet",
  "sync",
  "right_click",
  "bottom_right_click",
  "arrow_outward",
  "call",
  "check_circle",
  "home_pin",
  "account_balance_wallet",
  "universal_currency_alt",
  "dashboard",
  "join_right",
  "next_week",
  "waterfall_chart",
  "token",
  "offline_bolt",
];

export const dimensions = [
  "small",
  "base",
  "medium",
  "large",
  "default",
  "xlarge",
];
export const dimensionsMap = {
  small: 16,
  base: 18,
  medium: 20,
  large: 24,
  default: 32,
  xlarge: 42,
};
